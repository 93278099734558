import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { useUserService } from 'hooks/api/iam/user';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addOneUserToStore } from 'store/actions/user';

export const EarningPercentageUpdationForm = ({ data, closeModal = () => {} }) => {
  const dispatch = useDispatch();
  const { updateUserData } = useUserService();

  const [form_data, setFormData] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!data || !data.referral) return;
    const {
      referral: { percentage }
    } = data;
    setFormData({ percentage });
  }, [data]);

  const handleInputChange = (property, value) => {
    if (!property) return;
    setFormData((curr_values) => ({ ...curr_values, [property]: value }));
  };

  const submit = async () => {
    if (isNaN(form_data.percentage)) return;
    const data_to_save = { referral: { ...data.referral, percentage: form_data.percentage } };
    setSaving(true);
    const result = await updateUserData(data.id, { data: data_to_save });
    if (result) {
      toast.success('Earning percentage updated.');
      dispatch(addOneUserToStore({ ...data, ...data_to_save }));
      setFormData({});
      closeModal();
    }
    setSaving(false);
  };

  return (
    <>
      <GridRow num_of_columns={2}>
        <GridColumn span={2}>
          <DetailItem title="Earning Percent">
            <SimpleInput
              value={form_data.percentage}
              onInput={(v) => handleInputChange('percentage', v)}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn />
        <GridColumn>
          <Button text="Save Changes" onClick={submit} loading={saving} />
        </GridColumn>
      </GridRow>
    </>
  );
};
