import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import React, { useEffect, useState } from 'react';
import { PricingConfig } from './config';
import { PricingItem } from './item';

export const Pricing = ({ module, onChange = () => {} }) => {
  const [pricing_by_bracket, setPricingByBracket] = useState([]);
  const [pricing_to_edit, setPricingToEdit] = useState({});

  useEffect(() => {
    if (!module.pricing) return;
    setPricingByBracket(() =>
      (module.pricing || []).reduce((s, price) => {
        const {
          contact_bracket: { min, max }
        } = price;
        return {
          ...s,
          [`${min}:${max}`]: price
        };
      }, {})
    );
  }, [module.id]);

  const handleItemChange = (item) => {
    const {
      contact_bracket: { min, max }
    } = item;
    const curr_pricing = pricing_by_bracket;
    curr_pricing[`${min}:${max}`] = item;
    onChange(
      Object.values(curr_pricing).sort((a, b) => a.contact_bracket.min - b.contact_bracket.min)
    );
    setPricingByBracket(curr_pricing);
    setPricingToEdit(() => ({}));
  };

  return (
    <GridRow num_of_columns={4}>
      <GridColumn span={2}>
        <BasicInfoSection title="Pricing">
          {Object.values(pricing_by_bracket).map((p, index) => (
            <PricingItem data={p} key={`pricing-${index}`} onEdit={setPricingToEdit} />
          ))}
        </BasicInfoSection>
      </GridColumn>
      <GridColumn span={2}>
        <BasicInfoSection title="Pricing config">
          <PricingConfig data={pricing_to_edit} onSave={handleItemChange} />
        </BasicInfoSection>
      </GridColumn>
    </GridRow>
  );
};
