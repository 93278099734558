import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDataTable from 'app/shared/datatable/web/datatable';
import { addManyPlansToStore, addPlanSearchResultsToStore } from 'store/actions/plan';
import { setPageTitle } from 'store/actions/header';
import { Button } from 'app/shared/button';
import { useModuleService } from 'hooks/api/sales/module';

export const PlanListPage = () => {
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const plans_in_store = useSelector((state) => state.plans);
  const history = useHistory();

  const dispatch = useDispatch();
  const { fetchModules, searchModules } = useModuleService();

  const [items, setItems] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plan_count, setPlanCount] = useState(0);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Billing', path: '/billings' },
        { title: 'Plans', path: '/billings/plans' }
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItems(Object.values(plans_in_store));
  }, [plans_in_store]);

  const table_config = {
    actions: {
      single: () => [{ label: 'Edit', value: 'edit' }]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Title',
        key: 'title',
        searchable: true,
        isTitle: true
      },
      {
        title: 'Code',
        key: 'code',
        searchable: true,
        isTagline: true
      },
      {
        title: 'Category',
        key: 'category',
        isTagline: true
      },
      {
        title: 'Date created',
        key: 'created_on',
        formatter: (value) => {
          return value ? new Date(value).toDateString() : '';
        },
        isMetadata: true
      }
    ],
    items: items.sort((a, b) => b.id - a.id),
    search_text: ''
  };

  const handleDatatableAction = (action) => {
    const { name, type, data } = action;

    if (type.toLowerCase() === 'single') {
      switch (name.toLowerCase()) {
        case 'edit':
          history.push(`/billings/plans/${data.id}`);
          break;
        default:
      }
    }
  };

  const handleItemClick = (data) => {};

  const handleDataRequest = async (page) => {
    try {
      const { modules, size } = await fetchModules({
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });

      setPlanCount(size);
      dispatch(addManyPlansToStore(modules));
    } catch (e) {
      dispatch(addManyPlansToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { modules, size } = await searchModules(keys, keyword, {
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });

      if (page === 0) {
        return dispatch(addPlanSearchResultsToStore(modules));
      }

      dispatch(addManyPlansToStore(modules));
      setPlanCount(size);
    } catch (e) {
      dispatch(addManyPlansToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="Plan" onClick={() => history.push(`/billings/plans/new`)} />
    </>
  );

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={{
            ...table_config,
            is_search_mode,
            items,
            total_count: plan_count
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <div>
          <WebDataTable
            config={{
              ...table_config,
              is_search_mode,
              items,
              total_count: plan_count
            }}
            table_actions={table_actions}
            action={handleDatatableAction}
            onClick={handleItemClick}
            checkbox
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        </div>
      )}
    </div>
  );
};
