import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { useCurrencyService } from 'hooks/api/sales/currency';
import { useModuleService } from 'hooks/api/sales/module';
import { useSubscriptionService } from 'hooks/api/sales/subscriptions';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const RENEWAL_PERIODS = [
  { label: 'Monthly', value: 'month' },
  { label: 'Annually', value: 'year' }
];

export const SubscriptionAssignment = ({ tenant_id, closeModal }) => {
  const { fetchCurrencies } = useCurrencyService();
  const { fetchModules } = useModuleService();
  const { createSubscription, fetchSubscriptions } = useSubscriptionService();

  const [contact_brackets, setContactBrackets] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [modules, setModules] = useState([]);

  const [selected_bracket, setSelectedBracket] = useState({});
  const [selected_currency, setSelectedCurrency] = useState({});
  const [selected_period, setSelectedPeriod] = useState({});
  const [selected_module, setSelectedModule] = useState({});

  const [is_saving, setIsSaving] = useState(false);

  useEffect(() => {
    Promise.all([fetchCurrencies(), fetchModules()]).then(([{ currencies }, { modules }]) => {
      setCurrencies(currencies.map((c) => ({ ...c, label: c.name, value: c.code })));
      setModules(modules.map((p) => ({ ...p, label: p.title, value: p.code })));
    });
  }, []);

  useEffect(() => {
    if (!selected_module || !Object.keys(selected_module).length) return;

    const { pricing } = selected_module;
    const brackets = pricing.map((price_bracket) => {
      const {
        contact_bracket: { min, max }
      } = price_bracket;
      return {
        label: `${min.toLocaleString()} - ${max.toLocaleString()}`,
        value: min,
        ...price_bracket
      };
    });

    setContactBrackets(brackets);
  }, [selected_module]);

  const submit = async () => {
    const { subscriptions } = await fetchSubscriptions({
      query_string: `tenant_id=${tenant_id}&status=active`
    });

    const diff_currency = subscriptions.find((sub) => {
      const {
        price: { currency }
      } = sub;
      return currency?.code !== selected_currency?.code;
    });

    if (diff_currency && diff_currency.id) {
      toast.error(`Cannot assign a subscription with different currency.`);
      return;
    }

    const existing_subscription = subscriptions.find(
      (sub) => sub.plan_code === selected_module.code
    );
    if (existing_subscription && existing_subscription.id) {
      toast.error(`Tenant already has a ${selected_module.title} subscription.`);
      return;
    }

    const data = {
      currency: selected_currency,
      module: { ...selected_module, pricing: selected_bracket },
      renewal_period: selected_period.value,
      tenant_id
    };

    setIsSaving(true);
    try {
      const { subscription } = await createSubscription({ data });
      if (subscription) {
        setSelectedBracket({});
        setSelectedCurrency({});
        setSelectedModule({});
        setSelectedPeriod({});
        closeModal();
      }
    } finally {
      setIsSaving(false);
    }
  };

  const button_is_disabled =
    is_saving || !selected_currency.value || !selected_period.value || !selected_module.value;

  return (
    <>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="Currency">
            <MultiSelect
              options={currencies}
              value={selected_currency}
              onChange={setSelectedCurrency}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Module">
            <MultiSelect options={modules} value={selected_module} onChange={setSelectedModule} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="Contact Bracket">
            <MultiSelect
              options={contact_brackets}
              value={selected_bracket}
              onChange={setSelectedBracket}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Renewal period">
            <MultiSelect
              options={RENEWAL_PERIODS}
              value={selected_period}
              onChange={setSelectedPeriod}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        <GridColumn span={2} />
        <GridColumn>
          <Button text="Assign Subscription" onClick={submit} disabled={button_is_disabled} />
        </GridColumn>
      </GridRow>
    </>
  );
};
