import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as ReferralService from 'services/iam/referral';

export const useReferralService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchReferrals = async (options = {}) => {
    const { error, payload } = await ReferralService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch referrals at this time.');
      return { referrals: [], size: 0 };
    }

    const {
      data: referrals,
      meta: { size }
    } = payload;
    return { referrals, size };
  };

  const searchReferrals = async (keys, keyword, options = {}) => {
    const { error, payload } = await ReferralService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch referrals at this time.');
      return { referrals: [], size: 0 };
    }

    const {
      data: referrals,
      meta: { size }
    } = payload;
    return { referrals, size };
  };

  const updateReferral = async (id, options = {}) => {
    const { error, payload: referral } = await ReferralService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot settle referral at this time');
      return { referral: null, error };
    }

    toast.success('Referral settled successfully.');
    return { referral, error: null };
  };

  const updateReferrals = async (options = {}) => {
    const { error, payload } = await ReferralService.update({
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot settle referral at this time');
    } else {
      toast.success('Referrals settled successfully.');
    }
    return payload;
  };

  return { fetchReferrals, searchReferrals, updateReferral, updateReferrals };
};
