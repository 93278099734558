import { Spacer } from 'app/member/layouts/generic';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { Icon } from 'assets/icons';
import styles from './pricing.module.css';
import React, { useEffect, useState } from 'react';
import { useCurrencyService } from 'hooks/api/sales/currency';
import { Button } from 'app/shared/button';
import { toast } from 'react-toastify';

const DiscountItem = ({ data, onChange = () => {}, onRemove = () => {} }) => {
  const DISCOUNT_PERIODS = [
    {
      label: 'Monthly',
      value: 'month'
    },
    {
      label: 'Yearly',
      value: 'year'
    }
  ];
  const [period, setPeriod] = useState({});
  const [amount, setAmount] = useState(0);
  const [cycles, setCycles] = useState(0);

  useEffect(() => {
    if (!data || !data.period || data.period === period.value) return;
    setPeriod(() => DISCOUNT_PERIODS.find((dp) => dp.value === data.period));
    setAmount(data.value || 0);
    setCycles(data.cycles || 0);
  }, [data.period]);

  useEffect(() => {
    if (!period.value) return;
    onChange({
      period: period.value,
      value: Number(amount),
      cycles: Number(cycles)
    });
  }, [amount, period.value, cycles]);

  return (
    <GridRow num_of_columns={9}>
      <GridColumn span={4}>
        <DetailItem title="Period">
          <MultiSelect options={DISCOUNT_PERIODS} value={period} onChange={setPeriod} />
        </DetailItem>
      </GridColumn>
      <GridColumn span={2}>
        <DetailItem title="Percentage">
          <SimpleInput type="number" value={amount} onInput={setAmount} />
        </DetailItem>
      </GridColumn>
      <GridColumn span={2}>
        <DetailItem title="Cycles">
          <SimpleInput type="number" value={cycles} onInput={setCycles} />
        </DetailItem>
      </GridColumn>
      <GridColumn>
        <span className={styles.trashIcon}>
          <Icon name="trash" onClick={() => onRemove(period.value)} />
        </span>
      </GridColumn>
    </GridRow>
  );
};

const PriceItem = ({ currencies = [], data, onChange = () => {}, onRemove = () => {} }) => {
  const [selected_currency, setSelectedCurrency] = useState({});
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (!data.currency || data.currency === selected_currency.value) return;
    setSelectedCurrency(() => currencies.find((c) => c.code === data.currency));
    setAmount(data.amount || 0);
  }, [data.currency]);

  useEffect(() => {
    if (!selected_currency.value) return;
    onChange({
      currency: selected_currency?.value,
      amount: Number(amount)
    });
  }, [amount, selected_currency.value]);

  return (
    <GridRow num_of_columns={9}>
      <GridColumn span={4}>
        <DetailItem title="currency">
          <MultiSelect
            options={currencies}
            onChange={setSelectedCurrency}
            value={selected_currency}
          />
        </DetailItem>
      </GridColumn>
      <GridColumn span={4}>
        <DetailItem title="Amount">
          <SimpleInput type="number" onInput={setAmount} value={amount} />
        </DetailItem>
      </GridColumn>
      <GridColumn>
        <span className={styles.trashIcon}>
          <Icon name="trash" onClick={() => onRemove(selected_currency.value)} />
        </span>
      </GridColumn>
    </GridRow>
  );
};

export const PricingConfig = ({ data, onSave = () => {} }) => {
  const { fetchCurrencies } = useCurrencyService();

  const [contact_bracket, setContactBracket] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [discounts_by_period, setDiscountsByPeriod] = useState({});
  const [prices, setPrices] = useState([]);
  const [prices_by_currency, setPricesByCurrency] = useState({});
  const [resources, setResources] = useState(0);

  useEffect(() => {
    fetchCurrencies().then(({ currencies }) => {
      setCurrencies(
        currencies.map((currency) => ({ ...currency, label: currency.name, value: currency.code }))
      );
    });
  }, []);

  useEffect(() => {
    if (!Object.keys(data).length) return;

    const parsed_discounts = Object.keys(data.discounts).reduce(
      (s, period) => ({
        ...s,
        [period]: {
          ...data.discounts[period],
          period
        }
      }),
      {}
    );

    setContactBracket(data.contact_bracket);
    setDiscounts(Object.values(parsed_discounts));
    setDiscountsByPeriod(parsed_discounts);
    setPrices(data.prices);
    setPricesByCurrency((data.prices || []).reduce((s, p) => ({ ...s, [p.currency]: p }), {}));
    setResources(data.resources);
  }, [data]);

  const addDiscountItem = () => {
    if (discounts.length > 1) return;
    setDiscounts((curr) => [...curr, {}]);
  };

  const addPriceItem = () => {
    if (prices.length >= currencies.length) return;
    setPrices((curr) => [...curr, {}]);
  };

  const removeDiscountItem = (period) => {
    const curr_discounts_by_period = discounts_by_period;
    delete curr_discounts_by_period[period];
    setDiscountsByPeriod(curr_discounts_by_period);
    setDiscounts((curr_discounts) => curr_discounts.filter((d) => d.period !== period));
  };

  const removePriceItem = (currency) => {
    const curr_prices_by_currency = prices_by_currency;
    delete curr_prices_by_currency[currency];
    setPricesByCurrency(curr_prices_by_currency);
    setPrices((curr_prices) => curr_prices.filter((d) => d.currency !== currency));
  };

  const handleBracketChange = (key, value) => {
    if (!key) return;
    setContactBracket((brack) => ({ ...brack, [key]: Number(value) }));
  };

  const handleDiscountChange = (payload) => {
    const new_discounts = { ...discounts_by_period, [payload.period]: payload };
    setDiscountsByPeriod(() => new_discounts);
    setDiscounts(Object.values(new_discounts));
  };

  const handlePriceChange = (payload) => {
    const new_prices = { ...prices_by_currency, [payload.currency]: payload };
    setPricesByCurrency(() => new_prices);
    setPrices(Object.values(new_prices));
  };

  const submit = () => {
    if (
      !contact_bracket ||
      contact_bracket.min === undefined ||
      contact_bracket.max === undefined ||
      !contact_bracket.max ||
      Number(contact_bracket.max) < Number(contact_bracket.min)
    ) {
      return toast.error('Please specify a valid contact bracket.');
    } else if (!prices || prices.length !== currencies.length) {
      return toast.error('Please add a price for all currencies.');
    } else if (!discounts || discounts.length !== 2) {
      return toast.error('Please add a discount for all periods.');
    }

    onSave({
      contact_bracket,
      discounts: discounts_by_period,
      prices,
      resources: Number(resources)
    });

    setContactBracket({});
    setDiscounts([]);
    setDiscountsByPeriod({});
    setPrices([]);
    setPricesByCurrency({});
    setResources(0);
  };

  return (
    <>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="Min.contacts">
            <SimpleInput
              type="number"
              value={contact_bracket.min}
              onInput={(v) => handleBracketChange('min', v)}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Max.contacts">
            <SimpleInput
              type="number"
              value={contact_bracket.max}
              onInput={(v) => handleBracketChange('max', v)}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Resource Allowance">
            <SimpleInput type="number" value={resources} onInput={setResources} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <div className={styles.formSection}>
        <span>Discounts</span>
        <span className={styles.addIcon}>
          <Icon name="add" onClick={addDiscountItem} />
        </span>
      </div>
      <Spacer multiple={4} />
      {discounts.map((d, index) => (
        <DiscountItem
          key={`discount-${d.period || index}`}
          data={d}
          onChange={handleDiscountChange}
          onRemove={removeDiscountItem}
        />
      ))}
      <Spacer multiple={4} />
      <div className={styles.formSection}>
        <span>Prices</span>
        <span className={styles.addIcon}>
          <Icon name="add" onClick={addPriceItem} />
        </span>
      </div>
      <Spacer multiple={4} />
      {prices.map((price, index) => (
        <PriceItem
          key={`price-${price.currency || index}`}
          currencies={currencies}
          data={price}
          onChange={handlePriceChange}
          onRemove={removePriceItem}
        />
      ))}
      <Spacer multiple={4} />
      <Button text="Add Pricing" onClick={submit} />
    </>
  );
};
