import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { CreatePlanForm } from './details';
import { useParams } from 'react-router-dom';
import { Pricing } from './components/pricing';
import { useModuleService } from 'hooks/api/sales/module';

export const PlanCreation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { fetchModule } = useModuleService();

  const [plan, setPlan] = useState({});
  const [pricing, setPricing] = useState([]);

  useEffect(() => {
    if (isNaN(id)) return;
    fetchModule(id).then(({ module }) => {
      if (!module) return;
      setPlan(module);
    });
  }, [id]);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Billing', path: '.' },
        { title: 'Plan', path: '..' }
      ])
    );
  }, []);

  const handlePricingChange = (value) => {
    setPricing(value);
  };

  return (
    <GridRow
      tabletStyles={{ gridTemplateColumns: 'repeat(1,1fr)', gap: 0 }}
      mobileStyles={{ gap: 0 }}
    >
      <GridColumn>{<CreatePlanForm plan={plan} data={{ pricing }} />}</GridColumn>
      <GridColumn span={3}>
        <Pricing module={plan} onChange={handlePricingChange} />
      </GridColumn>
    </GridRow>
  );
};
