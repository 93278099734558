import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDataTable from 'app/shared/datatable/web/datatable';
import { addManyReferralsToStore, addReferralSearchResultsToStore } from 'store/actions/referrals';
import { setPageTitle } from 'store/actions/header';
import { useReferralService } from 'hooks/api/iam/referral';
import { truncateText } from 'app/shared/utils/general';
import { Badge } from '@arco-design/web-react';

export const EarningsListPage = () => {
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const referrals_in_store = useSelector((state) => state.referrals);

  const dispatch = useDispatch();
  const { fetchReferrals, searchReferrals } = useReferralService();

  const [items, setItems] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referral_count, setReferralCount] = useState(0);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Billing', path: '/billings' },
        { title: 'Earnings', path: '/billings/earnings' }
      ])
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItems(Object.values(referrals_in_store));
  }, [referrals_in_store]);

  const table_config = {
    actions: {
      single: () => []
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Referrer',
        key: 'referrer',
        formatter: (value) => `${value.firstname} ${value.lastname}`,
        isTitle: true
      },
      {
        title: 'Referrer email',
        key: 'referrer',
        isTagline: true,
        formatter: (value) => truncateText(value.email, 35)
      },
      {
        title: 'Referred',
        key: 'referred',
        formatter: (value) => value.name,
        isTagline: true
      },

      {
        title: 'Bonus',
        key: 'amount',
        isTagline: true
      },

      {
        title: 'Status',
        key: 'status',
        isTagline: true,
        formatter: (value) => (
          <Badge color={value === 'pending' ? 'orange' : 'green'} text={value} />
        )
      },
      {
        title: 'Date created',
        key: 'created_on',
        formatter: (value) => {
          return value ? new Date(value).toDateString() : '';
        },
        isMetadata: true
      }
    ],
    items,
    search_text: 'code,status'
  };

  const handleDatatableAction = async (payload) => {
    const { name, type } = payload;

    if (type === 'single') {
      switch (name) {
        default:
          break;
      }
    }
  };

  const handleItemClick = (data) => {};

  const handleDataRequest = async (page) => {
    try {
      const { referrals, size } = await fetchReferrals({
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });

      setReferralCount(size);
      dispatch(addManyReferralsToStore(referrals));
    } catch (e) {
      dispatch(addManyReferralsToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { referrals, size } = await searchReferrals(keys, keyword, {
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });

      if (page === 0) {
        return dispatch(addReferralSearchResultsToStore(referrals));
      }

      dispatch(addManyReferralsToStore(referrals));
      setReferralCount(size);
    } catch (e) {
      dispatch(addManyReferralsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = <></>;

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={{
            ...table_config,
            is_search_mode,
            items,
            total_count: referral_count
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <div>
          <WebDataTable
            config={{
              ...table_config,
              is_search_mode,
              items,
              total_count: referral_count
            }}
            table_actions={table_actions}
            action={handleDatatableAction}
            onClick={handleItemClick}
            checkbox
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        </div>
      )}
    </div>
  );
};
