import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PayoutListPage } from './list';

export const PayoutsModule = () => {
  return (
    <Switch>
      <Route path="/*" component={PayoutListPage} />
    </Switch>
  );
};
