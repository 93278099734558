import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDataTable from 'app/shared/datatable/web/datatable';
import { setPageTitle } from 'store/actions/header';
import { useReferralService } from 'hooks/api/iam/referral';
import { truncateText } from 'app/shared/utils/general';
import { toast } from 'react-toastify';

export const PayoutListPage = () => {
  const { is_mobile_view } = useSelector((state) => state.metadata);

  const dispatch = useDispatch();
  const { fetchReferrals, updateReferrals } = useReferralService();

  const [referrals, setReferrals] = useState({});
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referral_count, setReferralCount] = useState(0);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Billing', path: '/billings' },
        { title: 'Payouts', path: '/billings/payouts' }
      ])
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table_config = {
    actions: {
      single: () => [{ label: 'Settle', value: 'settle' }]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Referrer',
        key: 'referrer',
        formatter: (value) => `${value.firstname} ${value.lastname}`,
        isTitle: true
      },
      {
        title: 'Referrer email',
        key: 'referrer',
        isTagline: true,
        formatter: (value) => truncateText(value.email, 35)
      },
      {
        title: 'Bonus',
        key: 'amount',
        formatter: (value) => value.toLocaleString(),
        isTagline: true
      },
      {
        title: 'Status',
        key: 'status',
        searchable: true,
        formatter: () => 'pending',
        isBadge: true
      }
    ],
    search_text: 'search payouts'
  };

  const handleDatatableAction = async (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        case 'settle':
          handleSettlement(data);
          break;
        default:
          break;
      }
    }
  };

  const handleSettlement = async (data) => {
    const payload = { options: { code: data.code }, data: { status: 'settled' } };
    const result = await updateReferrals({ data: payload });
    if (result) {
      toast.success('Payout settled successfully.');
      const pending_referrals = referrals;
      delete pending_referrals[data.code];
      setReferrals(() => ({ ...pending_referrals }));
    }
  };

  const handleItemClick = (data) => {};

  const handleDataRequest = async (page) => {
    setLoading(true);
    try {
      const { referrals, size } = await fetchReferrals({
        query_string: `status=pending&sort_by=-created_on&page=${page}&population=1000`
      });

      const payout_by_referrer = {};
      referrals.forEach((referral) => {
        if (payout_by_referrer[referral.code]) {
          payout_by_referrer[referral.code].amount += referral.amount;
        } else {
          payout_by_referrer[referral.code] = {
            id: referral.code,
            code: referral.code,
            referrer: referral.referrer,
            amount: referral.amount
          };
        }
      });

      setReferralCount(size);
      setReferrals((curr_items) => ({ ...curr_items, ...payout_by_referrer }));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page) => {};

  const table_actions = <></>;

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={{
            ...table_config,
            is_search_mode,
            items: Object.values(referrals),
            total_count: referral_count
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <div>
          <WebDataTable
            config={{
              ...table_config,
              is_search_mode,
              items: Object.values(referrals),
              total_count: referral_count
            }}
            table_actions={table_actions}
            action={handleDatatableAction}
            onClick={handleItemClick}
            checkbox
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        </div>
      )}
    </div>
  );
};
