export const createPlanValidationConfig = {
  avatar: {
    required: true
  },
  category: {
    required: true
  },
  description: {
    required: true
  },
  title: {
    required: true
  }
};
