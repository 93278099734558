import { Spacer } from 'app/member/layouts/generic';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { toDateString } from 'app/shared/utils/date';
import { useModuleService } from 'hooks/api/sales/module';
import { useSubscriptionService } from 'hooks/api/sales/subscriptions';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const SubscriptionUpgrade = ({ data, onSave = () => {}, closeModal = () => {} }) => {
  const { fetchModules } = useModuleService();
  const { upgradeSubscription } = useSubscriptionService();

  const [contact_brackets, setContactBrackets] = useState([]);
  const [is_saving, setIsSaving] = useState(false);
  const [selected_bracket, setSelectedBracket] = useState({});

  useEffect(() => {
    if (!data || !data.id) return;
    fetchModules({ query_string: `code=${data.plan_code}` }).then(({ modules: [module] }) => {
      const brackets = module.pricing.map((p) => {
        const {
          contact_bracket: { min, max }
        } = p;
        return {
          label: `${min.toLocaleString()} - ${max.toLocaleString()}`,
          value: min,
          ...p
        };
      });
      setContactBrackets(brackets.filter((b) => b.value > data.bracket));
    });
  }, [data.plan_code]);

  const submit = async () => {
    const data_to_send = {
      ...data,
      selected_pricing: selected_bracket
    };

    setIsSaving(true);
    const { subscription } = await upgradeSubscription(data.id, { data: data_to_send });
    if (subscription) {
      toast.success('Subscripiton upgraded successfully');
      onSave();
      closeModal();
    }
    setIsSaving(false);
  };

  return (
    <>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="Title">{data.title}</DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Current Price">{data.price?.amount.toLocaleString()}</DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="Expiration Date">{toDateString(data.expires_at)}</DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Renewal period">{data.renewal_period}</DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Select contact bracket">
            <MultiSelect
              options={contact_brackets}
              value={selected_bracket}
              onChange={setSelectedBracket}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={2}>
        <GridColumn />
        <GridColumn>
          <Button text="Upgrade subscription" onClick={submit} loading={is_saving} />
        </GridColumn>
      </GridRow>
    </>
  );
};
