import '../../dashboard/dashboard.css';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { truncateText } from 'app/shared/utils/general';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { useSubscriptionService } from 'hooks/api/sales/subscriptions';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import GmModal from 'app/shared/modal/modal';
import { SubscriptionUpgrade } from '../actions/upgrade';

export const SubscriptionsListPage = () => {
  const { id: tenant_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { cancelSubscription, fetchSubscriptions, renewSubscription } = useSubscriptionService();

  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show_cancellation_confirmation, setShowCancellationConfirmation] = useState(false);
  const [show_confirmation, setShowConfirmation] = useState(false);
  const [show_upgrade_modal, setShowUpgradeModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState({});
  const [subscription_to_modify, setSubscriptionToModify] = useState({});
  const [total_subscriptions, setTotalSubscriptions] = useState(0);

  const config = {
    actions: {
      single: (subscription) => [
        {
          label: 'Cancel',
          value: 'cancel',
          hidden: subscription.status === 'inactive'
        },
        {
          label: 'Upgrade',
          value: 'upgrade',
          hidden: subscription.status === 'inactive'
        },
        {
          label: 'Renew',
          value: 'renew',
          hidden: subscription.status === 'inactive'
        },
        {
          label: 'Renew with charge',
          value: 'renew_charge',
          hidden: subscription.status === 'inactive'
        }
      ]
    },
    css: {},
    fields: [
      {
        title: 'Title',
        key: 'title',
        formatter: (value) => value || 'N/A',
        isTitle: true
      },
      {
        title: 'Code',
        key: 'plan_code',
        formatter: (value) => value || 'N/A',
        isTitle: true
      },
      {
        title: 'Cost',
        key: 'price',
        formatter: (value) => `${value.amount} ${value.currency.code}` || 'N/A',
        isTitle: true
      },
      {
        title: 'Renewal Period',
        key: 'renewal_period',
        isTagline: true,
        formatter: (value) => truncateText(value?.email, 25) || 'N/A'
      },
      {
        title: 'Date Purchased',
        key: 'purchased_at',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      },
      {
        title: 'Due Date',
        key: 'expires_at',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      },
      {
        title: 'Status',
        key: 'status',
        formatter: (value) => {
          switch (value) {
            case 'inactive':
              return (
                <span className="dangerBadge" style={{ backgroundColor: 'var(--neutral-dark-3)' }}>
                  {value}
                </span>
              );
            default:
              return (
                <span className="greenBadge" style={{ backgroundColor: 'var(--success)' }}>
                  {value}
                </span>
              );
          }
        }
      }
    ],
    is_search_mode,
    items: Object.values(subscriptions).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleCancellation = async (action_is_permitted) => {
    if (action_is_permitted) {
      await cancelSubscription(subscription_to_modify.id, { data: subscription_to_modify });
      handleDataRequest();
    }

    setShowCancellationConfirmation(false);
  };

  const handleConfirmation = async (action_is_permitted) => {
    if (action_is_permitted) {
      await renewSubscription(subscription_to_modify.id, { data: subscription_to_modify });
      handleDataRequest();
    }

    setShowConfirmation(false);
  };

  const handleDataRequest = async (page = 0, population = 50) => {
    if (loading) return;
    setLoading(true);

    const { subscriptions, size } = await fetchSubscriptions({
      query_string: `tenant_id=${tenant_id}&page=${page}&population=${population}&sort_by=-created_on`
    });

    setSubscriptions((curr_subscriptions) => ({
      ...curr_subscriptions,
      ...subscriptions.reduce(
        (s, a) => ({
          ...s,
          [a.id]: {
            ...a,
            bracket: a?.metadata?.pricing?.value
          }
        }),
        {}
      )
    }));
    setTotalSubscriptions(() => size);
    setLoading(false);
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      let renew_with_charge = false;
      switch (name) {
        case `cancel`:
          setShowCancellationConfirmation(true);
          setSubscriptionToModify({ ...data, renew_with_charge });
          break;
        case 'renew_charge':
          renew_with_charge = true;
        // eslint-ignore-next-line @no-fallthrough
        case `renew`:
          setShowConfirmation(true);
          setSubscriptionToModify({ ...data, renew_with_charge });
          break;
        case 'upgrade':
          setShowUpgradeModal(true);
          setSubscriptionToModify({ ...data });
          break;
        default:
      }
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  return (
    <>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={() => {}}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
          showHeader
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_subscriptions
          }}
          action={handleDatatableAction}
          onClick={() => {}}
          checkbox
          loading_data={loading}
          table_actions={[]}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
      <ConfirmationDialog
        message="Are you sure you want to renew this subscription? This action may not be reversible."
        callback={handleConfirmation}
        is_open={show_confirmation}
      />
      <ConfirmationDialog
        message="Are you sure you want to cancel this subscription? This action may not be reversible."
        callback={handleCancellation}
        is_open={show_cancellation_confirmation}
      />
      <GmModal
        show_modal={show_upgrade_modal}
        show_title={true}
        onClose={() => setShowUpgradeModal(false)}
        title="Upgrade Subscription"
      >
        <></>
        <SubscriptionUpgrade data={subscription_to_modify} onSave={handleDataRequest} />
      </GmModal>
    </>
  );
};
