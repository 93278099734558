import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as ModuleService from 'services/sales/module';

export const useModuleService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createModule = async (options = {}) => {
    const { error, payload: module } = await ModuleService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot create module at this time');
    } else {
      toast.success('module created successfully.');
    }

    return { module };
  };

  const deleteModule = async (id, options = {}) => {
    const { error } = await ModuleService.deleteById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot delete module at this time');
      return false;
    }

    toast.success('module deleted successfully.');
    return true;
  };

  const fetchModule = async (id, options = {}) => {
    const { error, payload } = await ModuleService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch module at this time.');
      return { module: {} };
    }

    return { module: payload };
  };

  const fetchModules = async (options = {}) => {
    const { error, payload } = await ModuleService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch modules at this time.');
      return { modules: [], size: 0 };
    }

    const {
      data: modules,
      meta: { size }
    } = payload;
    return { modules, size };
  };

  const searchModules = async (keys, keyword, options = {}) => {
    const { error, payload } = await ModuleService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch modules at this time.');
      return { modules: [], size: 0 };
    }

    const {
      data: modules,
      meta: { size }
    } = payload;
    return { modules, size };
  };

  const updateModule = async (id, options = {}) => {
    const { error, payload: module } = await ModuleService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot update module at this time');
    } else {
      toast.success('module updated successfully.');
    }

    return { module };
  };

  return {
    createModule,
    deleteModule,
    fetchModule,
    fetchModules,
    searchModules,
    updateModule
  };
};
