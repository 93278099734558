import { table_config } from './helper';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDataTable from 'app/shared/datatable/web/datatable';

import {
  addManyTenantsToStore,
  addTenantSearchResultsToStore,
  removeOneTenantFromStore
} from 'store/actions/tenant';
import { setPageTitle } from 'store/actions/header';
import GmModal from 'app/shared/modal/modal';
import { WalletTopUp } from 'app/shared/topup';
import styles from './list.module.css';
import { WalletContext } from 'app/hooks/wallet-context';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { useTenantService } from 'hooks/api/iam/tenant';
import { usePurgeService } from 'hooks/api/purge';
import { SubscriptionAssignment } from '../subscription/assign';

const ListTenants = () => {
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const tenants_in_store = useSelector((state) => state.tenants);

  const dispatch = useDispatch();
  const history = useHistory();
  const { tenant_wallet_list } = useContext(WalletContext);
  const { purgeAccount } = usePurgeService();
  const { fetchTenants, searchTenants } = useTenantService();

  const [items, setItems] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tenant_count, setTenantCount] = useState(0);

  const [selected_tenant_id, setSelectedTenantId] = useState(0);
  const [show_topup_modal, setShowTopUpModal] = useState(false);
  const [show_assignment_modal, setShowAssignmentModal] = useState(false);
  const [show_tenant_delete_modal, setShowTenantDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Tenants', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItems(Object.values(tenants_in_store));
  }, [tenants_in_store]);

  const handleDatatableAction = (action) => {
    const { name, type, data } = action;

    if (type.toLowerCase() === 'single') {
      switch (name.toLowerCase()) {
        case 'assign_subscription':
          setSelectedTenantId(() => data.id);
          setShowAssignmentModal(true);
          break;
        case 'edit':
          history.push(`/tenants/${data.id}/edit`);
          break;

        case 'top up':
          setSelectedTenantId(() => data.id);
          setShowTopUpModal(true);
          break;

        case 'delete':
          setSelectedTenantId(() => data.id);
          setShowTenantDeleteModal(true);
          break;
        default:
          history.push(`/tenants/${data.id}/dashboard`);
      }
    }
  };

  const handleItemClick = (data) => {
    history.push(`/tenants/${data.id}/dashboard`);
  };

  const handleDataRequest = async (page) => {
    try {
      const { tenants, size } = await fetchTenants({
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });
      setTenantCount(size);
      dispatch(
        addManyTenantsToStore(
          tenants.map((tenant) => ({
            ...tenant,
            ...tenant_wallet_list[tenant.id]
          }))
        )
      );
    } catch (e) {
      dispatch(addManyTenantsToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { tenants, size } = await searchTenants(keys, keyword, {
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });
      const parsed_tenants = tenants.map((tenant) => ({
        ...tenant,
        ...tenant_wallet_list[tenant.id]
      }));

      if (page === 0) {
        return dispatch(addTenantSearchResultsToStore(parsed_tenants));
      }

      dispatch(addManyTenantsToStore(parsed_tenants));
      setTenantCount(size);
    } catch (e) {
      dispatch(addManyTenantsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSingleDeletion = async (permitted) => {
    if (permitted) {
      const result = await purgeAccount(selected_tenant_id);

      if (result) dispatch(removeOneTenantFromStore(selected_tenant_id));
    }

    setShowTenantDeleteModal(false);
  };

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={{
            ...table_config,
            is_search_mode,
            items,
            total_count: tenant_count
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <div>
          <WebDataTable
            config={{
              ...table_config,
              is_search_mode,
              items,
              total_count: tenant_count
            }}
            action={handleDatatableAction}
            onClick={handleItemClick}
            checkbox
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        </div>
      )}

      <ConfirmationDialog
        title="Delete Tenant"
        message="Are you sure you want to delete this tenant?"
        callback={handleSingleDeletion}
        is_open={show_tenant_delete_modal}
      />
      <GmModal
        title="Wallet Top Up"
        show_title
        show_modal={show_topup_modal}
        onClose={() => setShowTopUpModal(false)}
        bodyClassName={styles.topupModal}
      >
        <WalletTopUp tenant_id={selected_tenant_id} />
      </GmModal>
      <GmModal
        title="Assign Subscription"
        show_title
        show_modal={show_assignment_modal}
        onClose={() => setShowAssignmentModal(false)}
      >
        <SubscriptionAssignment tenant_id={selected_tenant_id} />
      </GmModal>
    </div>
  );
};

export default ListTenants;
