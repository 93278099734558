import { Icon } from 'assets/icons';
import styles from './pricing.module.css';
import React, { useEffect, useState } from 'react';

export const PricingItem = ({ data, onEdit = () => {} }) => {
  const [contact_bracket, setContactBracket] = useState({});

  useEffect(() => {
    if (!data.contact_bracket) return;
    setContactBracket(data.contact_bracket);
  }, data);

  return (
    <div className={styles.priceItem}>
      <span>{`${contact_bracket.min?.toLocaleString()} - ${contact_bracket.max?.toLocaleString()} contacts`}</span>
      <span className={styles.editIcon}>
        <Icon name="edit" onClick={() => onEdit(data)} />
      </span>
    </div>
  );
};
