import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'app/shared/button';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { createPlanValidationConfig } from 'app/shared/utils/form/validation/plan';
import useValidator from 'hooks/validation';
import { MultiSelect } from 'app/shared/select';
import { TextArea } from 'app/shared/input/textarea';
import { useModuleService } from 'hooks/api/sales/module';
import { useResourceService } from 'hooks/api/iam/resource';
import { toast } from 'react-toastify';

const CONFIG_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const CreatePlanForm = ({ plan = {}, data }) => {
  const history = useHistory();
  const { createModule, updateModule } = useModuleService();
  const { fetchResources } = useResourceService();
  const { errors, validateField, form_is_valid } = useValidator(createPlanValidationConfig);

  const [loading, setLoading] = useState(false);
  const [basic_info, setBasicInfo] = useState({
    avatar: '',
    category: '',
    description: '',
    title: ''
  });
  const [code_options, setCodeOptions] = useState([]);
  const [can_be_configured, setCanBeConfigured] = useState({});
  const [selected_code, setSelectedCode] = useState({});

  useEffect(() => {
    fetchResources().then(({ resources }) => {
      setCodeOptions(resources.map((res) => ({ ...res, label: res.name, value: res.code })));
    });
  }, []);

  useEffect(() => {
    if (!Object.keys(plan).length) return;

    const info = {
      avatar: plan.avatar || '',
      category: plan.category || '',
      description: plan.description || '',
      title: plan.title || ''
    };

    setBasicInfo(info);
    setCanBeConfigured(CONFIG_OPTIONS.find((opt) => opt.value === plan.can_be_configured));
    setSelectedCode(code_options.find((opt) => opt.value === plan.code));

    Object.keys(info).forEach((key) => handleBasicInfoChange(key, info[key]));
  }, [plan]);

  const submitForm = async () => {
    const pricing = data.pricing.length ? data.pricing : plan.pricing || [];
    if (!pricing || !pricing.length) {
      return toast.error('specify atleast one contact bracket.');
    }
    const payload = {
      ...basic_info,
      can_be_configured: can_be_configured.value,
      code: selected_code.value,
      pricing
    };

    setLoading(() => true);
    let result = {};
    if (plan.id) {
      result = await updateModule(plan.id, { data: payload });
    } else {
      result = await createModule({ data: payload });
    }

    if (result.module) {
      history.push(`/billings/plans`);
    }
    setLoading(() => false);
  };

  const handleBasicInfoChange = (key, value) => {
    validateField(key, value);
    setBasicInfo((curr_info) => ({
      ...curr_info,
      [key]: value
    }));
  };

  return (
    <BasicInfoSection title="Basic Details">
      <DetailItem title="Title">
        <SimpleInput
          input_id="title"
          value={basic_info.title}
          onInput={(value) => handleBasicInfoChange('title', value)}
          error={errors.title}
          required
        />
      </DetailItem>
      <DetailItem title="Code">
        <MultiSelect
          options={code_options}
          value={selected_code}
          onChange={setSelectedCode}
          error={errors.code}
        />
      </DetailItem>
      <DetailItem title="Avatar">
        <SimpleInput
          input_id="avatar"
          value={basic_info.avatar}
          onInput={(value) => handleBasicInfoChange('avatar', value)}
          error={errors.avatar}
        />
      </DetailItem>
      <DetailItem title="Description">
        <TextArea
          input_id="description"
          value={basic_info.description}
          onInput={(value) => handleBasicInfoChange('description', value)}
          error={errors.description}
        />
      </DetailItem>
      <DetailItem title="Category">
        <SimpleInput
          input_id="category"
          value={basic_info.category}
          error={errors.category}
          onInput={(value) => handleBasicInfoChange('category', value)}
        />
      </DetailItem>
      <DetailItem title="Needs Configuration">
        <MultiSelect
          value={can_be_configured}
          options={CONFIG_OPTIONS}
          onChange={setCanBeConfigured}
          error={errors.can_be_configured}
        />
      </DetailItem>
      <Button onClick={submitForm} text="Save" loading={loading} disabled={!form_is_valid} />
    </BasicInfoSection>
  );
};
