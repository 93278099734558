import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as UserService from 'services/iam/user';

export const useUserService = () => {
  const { token } = useSelector((store) => store.user_data);

  const deactivateUser = async (id, options = {}) => {
    const { error, payload } = await UserService.deactivate(id, { ...options, token });
    if (error) {
      toast.error('Unable to deactivate user at this time.');
      return { user: null };
    }

    return { user: payload };
  };

  const fetchUser = async (id, options = {}) => {
    const { error, payload } = await UserService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch user at this time.');
      return { user: {} };
    }

    return { user: payload };
  };

  const fetchUsers = async (options = {}) => {
    const { error, payload } = await UserService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch users at this time.');
      return { users: [], size: 0 };
    }

    const {
      data: users,
      meta: { size }
    } = payload;
    return { users, size };
  };

  const searchUsers = async (keys, keyword, options = {}) => {
    const { error, payload } = await UserService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch users at this time.');
      return { users: [], size: 0 };
    }

    const {
      data: users,
      meta: { size }
    } = payload;
    return { users, size };
  };

  const updateUserData = async (id, options = {}) => {
    const { error, payload } = await UserService.updateById(id, {
      ...options,
      token
    });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  return { deactivateUser, fetchUser, fetchUsers, searchUsers, updateUserData };
};
